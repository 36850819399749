import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <footer>
      <ul>
        <li>
          <a 
            href="https://www.google.com/maps/place/8+Rue+%C3%89troite,+68000+Colmar/@48.07904,7.3598827,17z/data=!3m1!4b1!4m6!3m5!1s0x479165e14fb9f415:0x58bfdb745bed445d!8m2!3d48.07904!4d7.3598827!16s%2Fg%2F11c43zlsn8?entry=ttu" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Adresse
          </a>
        </li>
        <li>
          <a href="mailto:olinux@hotmail.com">Email</a>
        </li>
        <li>
          <a href="tel:0770326276">Tel : 0770326276</a>
        </li>
      </ul>
      <div className="copyright">
        &copy; {new Date().getFullYear()} Ubercat.fr  Tous droits réservés.
      </div>
    </footer>
  );
}

export default Footer;
