import React from 'react';
import './AboutPage.scss';

const AboutPage = () => {
  return (
    <div className="about-page">
      <h1>À propos de UberCat.fr</h1>
      <section className="mission-values">
        <h2>Notre Mission et Nos Valeurs</h2>
        <p>À UberCat.fr, notre mission est simple : rendre les amoureux du Ragdoll heureux. Nous croyons que chaque chat mérite une vie heureuse et épanouie, et nous nous engageons à garantir le bien-être de nos félins.</p>
      </section>
      <section className="cats">
        <h2>Nos Chats</h2>
        <p>Nous sommes situés au cœur de Colmar, en Alsace. Nous sommes une petite chatterie familiale et notre élevage se concentre principalement sur les Ragdolls. Nos chats vivent dans un environnement chaleureux et sécurisé, et sont élevés avec amour et soin.</p>
      </section>
      <section className="adoption">
        <h2>Adoption</h2>
        <p>Le processus d'adoption chez UberCat.fr est officiel et conforme aux normes établies par le Livre Officiel des Origines Félines (LOOF). Nous accordons une grande importance à trouver des familles aimantes et responsables pour nos chats. Nos conditions d'adoption sont simples : aimer et garantir une vie heureuse à nos chats.</p>
      </section>
      <section className="avis">
        <h2>Avis Google</h2>
        <p>Chez UberCat.fr, la satisfaction de nos clients est notre priorité absolue. Nous sommes fiers de partager les retours positifs que nous recevons sur Google. <a href="https://www.google.com/search?rlz=1C2UEAD_frFR1098FR1098&sca_esv=421dc2cbf8759f89&sca_upv=1&tbm=lcl&sxsrf=ADLYWIJRswMYKF2EIr9kWMUnssRTuVVKBw:1717351034107&q=Ubercat.fr%20Avis&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNDS2NLI0tjAxNzA0MbY0NDQxN9zAyPiKkT80KbUoObFEL61IwbEss3gRK7oIAGv3Hn4_AAAA&rldimm=11392938470143911471&hl=fr-FR&sa=X&ved=0CAUQ5foLahcKEwjo2cmSv72GAxUAAAAAHQAAAAAQBQ&biw=1718&bih=1121&dpr=1#lkt=LocalPoiReviews&arid=ChdDSUhNMG9nS0VJQ0FnSUN6dU1yTHJ3RRAB" target="_blank" rel="noopener noreferrer">
          Découvrez ce que nos clients ont à dire sur leur expérience avec notre chatterie
        </a></p>
      </section>
      <section className="faq">
        <h2>Des questions ?</h2>
        <p>Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter directement à l'adresse e-mail <a href="mailto:olinux@hotmail.com">olinux@hotmail.com</a> ou par téléphone au 0770326276.</p>
      </section>
    </div>
  );
}

export default AboutPage;
