import React from 'react';
import './Header.scss';

const Header = () => {
  return (
    <header>
      <nav>
        <ul>
          <li><a href="/" aria-label="Accueil">Accueil</a></li>
          <li><a href="/about" aria-label="À propos">À propos</a></li>
          <li><a href="/contact" aria-label="Contact">Contact</a></li>
          <li><a href="/live" aria-label="Vidéo Live" target="_blank" rel="noopener noreferrer">Vidéo Live</a></li>
          <li><a href="/gallery" aria-label="Galerie">Galerie</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
