import React, { useState } from 'react';
import './ImageGallery.css';

// Fonction pour lister les répertoires dans 'images'
function listDirectories(r) {
    const directories = {};

    r.keys().forEach((key) => {
        const parts = key.replace('./', '').split('/'); // Supprimer './' et diviser par '/'
        let current = directories;

        parts.forEach((part, index) => {
            if (!current[part]) {
                current[part] = index === parts.length - 1 ? [] : {};
            }
            if (index === parts.length - 1) {
                current[part].push(r(key));
            } else {
                current = current[part];
            }
        });
    });

    return directories;
}

// Importer toutes les images du répertoire 'images'
const directories = listDirectories(require.context('../../images', true, /\.(png|jpe?g|svg)$/));

const Directory = ({ dirName, content, showImages, toggleShowImages }) => {
    const isLeaf = Array.isArray(content);

    return (
        <div className="directory">
            <h2 onClick={() => toggleShowImages(dirName)} className="directory-title">{dirName}</h2>
            {showImages[dirName] && (
                <div className="directory-content">
                    {isLeaf ? (
                        <div className="image-grid">
                            {content.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img src={image} alt={`Galerie ${index + 1}`} onClick={() => window.open(image, '_blank')} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        Object.keys(content).map((subDir, index) => (
                            <Directory
                                key={index}
                                dirName={`${dirName}/${subDir}`}
                                content={content[subDir]}
                                showImages={showImages}
                                toggleShowImages={toggleShowImages}
                            />
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

function ImageGallery() {
    const [showImages, setShowImages] = useState({});

    const toggleShowImages = (dir) => {
        setShowImages((prevState) => ({
            ...prevState,
            [dir]: !prevState[dir],
        }));
    };

    return (
        <div className="gallery-container">
            {Object.keys(directories).map((dir, index) => (
                <Directory
                    key={index}
                    dirName={dir}
                    content={directories[dir]}
                    showImages={showImages}
                    toggleShowImages={toggleShowImages}
                />
            ))}
        </div>
    );
}

export default ImageGallery;