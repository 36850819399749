import React from 'react';
import './HomePage.scss';

const HomePage = () => {
  return (
    <div className="home-page">
      <h1>Bienvenue sur UberCat.fr</h1>
      <p>Découvrez l'élégance et la grâce incarnées chez UberCat.fr, votre portail vers le monde envoûtant des chats Ragdoll. Plongez dans l'univers captivant de cette race magnifique, réputée pour sa personnalité douce et ses traits distinctifs.</p>
      <p>Que vous soyez un passionné de longue date ou un nouvel amateur de ces félins majestueux, notre élevage dédié vous invite à explorer notre collection de Ragdolls, soigneusement sélectionnés pour leur beauté, leur tempérament et leur lignée prestigieuse.</p>
      <p>Chez UberCat.fr, nous nous engageons à offrir une expérience unique à chaque visiteur. De la consultation de notre galerie de photos à l'obtention de conseils d'experts sur le soin et l'entretien des Ragdolls, notre site est conçu pour vous accompagner dans votre parcours avec ces compagnons adorables.</p>
      <p>Nous sommes ravis de vous accueillir dans notre communauté dédiée aux amoureux des chats Ragdoll. Explorez, apprenez et laissez-vous charmer par ces merveilleux félins dont la beauté et la grâce sont incomparables. Bienvenue sur UberCat.fr, où chaque miaulement est une invitation à l'amour et à la complicité.</p>
      
      <div className="cat-photos">
        <h2>Nos Chatons Ragdoll vendus</h2>
        {/* Insérer des photos de chats ici */}
        <a href="./58BB3305-C657-4E4E-BBBA-B2B90EB1F2C2.jpg" target="_blank" rel="noopener noreferrer">
          <img src="./58BB3305-C657-4E4E-BBBA-B2B90EB1F2C2.jpg" alt="Chat Ragdoll 1" />
        </a>
        <a href="./20231202_004318.jpg" target="_blank" rel="noopener noreferrer">
          <img src="./20231202_004318.jpg" alt="Chat Ragdoll 2" />
        </a>
        <a href="./20240320_192249.jpg" target="_blank" rel="noopener noreferrer">
          <img src="./20240320_192249.jpg" alt="Chat Ragdoll 3" />
        </a>
        <a href="./IMG20231103115000.jpg" target="_blank" rel="noopener noreferrer">
          <img src="./IMG20231103115000.jpg" alt="Chat Ragdoll 4" />
        </a>
      </div>
    </div>
  );
}

export default HomePage;
