import React, { useEffect, useState } from 'react';
import './GalleryPage.scss';

const GalleryPage = () => {
  const [twitchEmbedCreated, setTwitchEmbedCreated] = useState(false);

  useEffect(() => {
    const loadTwitchEmbedScript = () => {
      const scriptId = 'twitch-embed-script';
      let script = document.getElementById(scriptId);

      if (!script) {
        script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://embed.twitch.tv/embed/v1.js';
        script.async = true;
        document.body.appendChild(script);
      }

      script.onload = () => {
        if (!twitchEmbedCreated) {
          new window.Twitch.Embed('twitch-embed', {
            width: '100%',
            height: '480',
            channel: 'ubercat_fr',
            parent: ['ubercat.fr'],
            layout: 'video',
          });
          setTwitchEmbedCreated(true);
        }
      };
    };

    loadTwitchEmbedScript();

    // Cleanup function
    return () => {
      const script = document.getElementById('twitch-embed-script');
      if (script) {
        script.onload = null; // Clean up onload handler
      }
    };
  }, [twitchEmbedCreated]);

  return (
    <div className="gallery-page">
      <h1>Vue sur le nid de Lili en direct</h1>
      <div id="twitch-embed"></div>
    </div>
  );
};

export default GalleryPage;
